/**
 * Any CSS included here will be global. The classic template
 * bundles Infima by default. Infima is a CSS framework designed to
 * work well for content-centric websites.
 */

@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,400;0,500;0,700;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital@0;1&display=swap');

/* You can override the default Infima variables here. */
:root {
  --fun-safe-serif: 'Noto Serif', serif;
  --fun-safe-sans: 'Noto Sans', sans-serif;

  --ifm-font-family-base: 'Alegreya Sans', sans-serif;
  --ifm-font-size-base: 20px;
  --ifm-h1-font-size: 24px; // 1.55
  --ifm-h2-font-size: 26px; // 1.33
  --ifm-h3-font-size: 22px; // 1.11
  --ifm-h4-font-size: 21px; // 1.08
  --ifm-container-width-xl: 1200px;
  --ifm-line-height-base: 1.6;
  --ifm-small-font-size: 15px;
  --ifm-menu-link-padding-horizontal: 15px;
  --ifm-menu-link-sublist-icon: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24"><path fill="rgb(53, 120, 229)" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path></svg>');
  --ifm-anchor-icon: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.87568 23.9575C8.72203 23.9575 7.5943 23.6154 6.63506 22.9745C5.67583 22.3336 4.92816 21.4227 4.48661 20.3569C4.04506 19.2911 3.92944 18.1183 4.15438 16.9868C4.37932 15.8553 4.93472 14.8159 5.75034 14L8.22484 11.5243L9.87451 13.174L7.40001 15.6485C6.74358 16.3049 6.3748 17.1952 6.3748 18.1236C6.3748 19.0519 6.74358 19.9422 7.40001 20.5987C8.05644 21.2551 8.94676 21.6239 9.87509 21.6239C10.8034 21.6239 11.6937 21.2551 12.3502 20.5987L14.8235 18.1242L16.4743 19.775L13.9998 22.2495C13.4593 22.7928 12.8163 23.2234 12.1082 23.5166C11.4001 23.8098 10.6409 23.9596 9.87451 23.9575H9.87568ZM10.7005 18.949L9.05084 17.2993L17.2992 9.04983L18.95 10.6995L10.7017 18.9478L10.7005 18.949V18.949ZM19.776 16.4745L18.1252 14.8248L20.5985 12.3503C20.9329 12.0275 21.1996 11.6414 21.3831 11.2144C21.5667 10.7875 21.6633 10.3282 21.6675 9.86349C21.6716 9.39876 21.5832 8.93787 21.4073 8.50769C21.2314 8.07751 20.9716 7.68667 20.6431 7.35797C20.3145 7.02926 19.9238 6.76929 19.4937 6.5932C19.0636 6.41712 18.6028 6.32845 18.138 6.33238C17.6733 6.33631 17.214 6.43275 16.7869 6.61608C16.3599 6.79941 15.9736 7.06596 15.6507 7.40017L13.175 9.87467L11.5253 8.225L13.9998 5.74933C15.0985 4.67834 16.5749 4.08331 18.1091 4.09314C19.6434 4.10297 21.112 4.71687 22.1968 5.80185C23.2816 6.88684 23.8953 8.35554 23.905 9.8898C23.9146 11.4241 23.3193 12.9003 22.2482 13.9988L19.7748 16.4733V16.4745H19.776Z' fill='%234C93C6'/%3E%3C/svg%3E%0A");
  --ifm-arrow-left-icon: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath transform='rotate(180 6 5)' d='M7.344 9.792L12 5.064V4.752L7.344 0L6.288 0.312V0.6L9.84 4.296L0.911999 4.2L0.768 4.344L0.863999 5.568L9.864 5.496L6.288 9.192V9.504L7.344 9.792Z' fill='%234C587B'/%3E%3C/svg%3E");
  --ifm-arrow-right-icon: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.344 9.792L12 5.064V4.752L7.344 0L6.288 0.312V0.6L9.84 4.296L0.911999 4.2L0.768 4.344L0.863999 5.568L9.864 5.496L6.288 9.192V9.504L7.344 9.792Z' fill='%234C587B'/%3E%3C/svg%3E%0A");

  font-variant-numeric: lining-nums;

  &[data-theme='light'] {
    --fun-color-primary-dark: #001B2E;
    --fun-color-primary-regular: #4C587B;
    --fun-color-primary-accent: #4C93C6;
    --fun-color-primary-light: #A1B4D1;
    --fun-color-primary-background: #DDE9FB;
    --fun-color-primary-background-contrast: #FFF;

    --fun-color-tip-background: #CCE8D1;
    --fun-color-tip-accent: #008A19;
    --fun-color-tip-dark: #002C05;

    --fun-color-warning-background: #FDF2E8;
    --fun-color-warning-accent: #F2994A;
    --fun-color-warning-dark: #2C1800;

    --fun-color-error-background: #FDE8E8;
    --fun-color-error-accent: #EB5757;
    --fun-color-error-dark: #2E0000;

    --ifm-background-color: #fff;
    --ifm-table-head-background: #d2e1f9;
    --ifm-table-border-color: #8dabda;
    --ifm-table-stripe-background: transparent;

    --ifm-color-secondary-contrast-background: #f5f9ff;
    --ifm-color-secondary-contrast-foreground: #001b2e;
    --ifm-color-secondary-dark: #d2e1f9;
  }

  &[data-theme='dark'] {
    --ifm-color-primary: #3b91e3;
    --ifm-color-primary-darkest: #a8d5ff;
    --ifm-background-color: #1a2933;
    --ifm-background-surface-color: #213340;
    --ifm-footer-background-color: #213340;
    --ifm-button-color: #daeaf2;
    --ifm-color-secondary-contrast-background: #213340;
    --ifm-color-secondary-dark: #445e71;
    //--fun-color-primary-dark: #001B2E;
    //--fun-color-primary-regular: #4C587B;
    //--fun-color-primary-accent: #4C93C6;
    //--fun-color-primary-light: #A1B4D1;
    //--fun-color-primary-background: #DDE9FB;
    //--fun-color-primary-background-contrast: #FFF;

    --ifm-table-head-background: #213340;
    --ifm-table-border-color: #445e71;
    --ifm-table-stripe-background: transparent;
  }
}

body[data-theme='light'] {
  --aa-primary-color-rgb: 76,147,198 !important;
  --aa-muted-color-rgb: 76,147,198 !important;
}

body[data-theme='dark'] {
  --aa-primary-color-rgb: 76,147,198 !important;
  --aa-muted-color-rgb: 76,147,198 !important;
}

.navbar__logo {
  height: 26px;
}

.aa-DetachedSearchButton {
  background: none !important;
  border: none !important;
}

.aa-DetachedSearchButtonPlaceholder {
  display: none;
}

.hero {
  background: none;

  &__subtitle {
    font-size: var(--ifm-h3-font-size);
  }
}

/*#region Markdown */
.markdown {
  h1:first-child {
    line-height: 1.286;
    //margin-bottom: 0.25em;
  }

  h2 {
    font-weight: normal;
    line-height: 1.33;
  }

  //h1 + h2 {
  //  margin-top: 0;
  //}

  a {
    text-decoration: underline;
  }

  a.card {
    text-decoration: none;
  }
}

abbr {
  text-decoration: none;
  font-style: italic;
  padding-bottom: 1px;
  border-bottom: 2px dotted var(--ifm-color-gray-400);
  cursor: pointer;
  position: relative;

  th > & {
    border-bottom-color: var(--ifm-color-primary-darkest);
  }

  &::before, &::after {
    position: absolute;
  }

  &::before {
    bottom: 100%;
    margin-left: -50%;
    padding: 0.5rem;
    background: var(--ifm-color-info-contrast-background);
    color: var(--ifm-color-info-contrast-foreground);
    border: 1px solid var(--ifm-color-info-border-color);
    border-radius: 0.25rem;
    font-style: normal;
    font-size: 0.8rem;
    white-space: nowrap;
  }

  &::after {
    bottom: calc(100% - 1rem);
    left: 50%;
    margin-left: -0.5rem;
    border-width: 0.5rem;
    border-style: solid;
    border-color: var(--ifm-color-info-contrast-background) transparent transparent transparent;
  }

  &:hover, &:focus {
    &::before {
      content: attr(title);
    }

    &::after {
      content: '';
    }
  }
}

div[lang], span[lang] {
  font-family: var(--fun-safe-serif);
  font-style: italic;
  font-size: 90%;

  td & {
    font-family: var(--fun-safe-sans);
    font-style: normal;
    font-size: 80%;
  }

  h1 &, h2 &, h3 & {
    font-family: inherit;
    font-size: 100%;
  }

  code {
    font-size: 90%;
  }

  > strong {
    font-weight: bolder;
    text-decoration: underline;
  }
}

th[lang*=isv] {
  font-family: var(--fun-safe-sans);
}

td[lang*=isv] {
  font-family: var(--fun-safe-sans);
  font-size: 0.8rem;
}

code {
  background: initial;
  border: initial;
  vertical-align: initial;
  white-space: pre;
  font: normal bold 0.8rem var(--fun-safe-sans);

  h1 &, h2 &, h3 & {
    font: inherit;
  }
}

mark {
  background-color: var(--ifm-color-warning-lightest);
}

.table_full {
  width: 100%;
  display: table;
}

.table_mw {
  width: 100%;
  max-width: 360px;
  display: table;
}

table .invisible {
  background: var(--ifm-background-color) !important;
  border-left: none;
  border-top: none;
}

table .noLeft {
  border-left-width: 1px !important;
}

table .noRight {
  border-right-width: 1px !important;
}

.table_sticky {
  border-collapse: separate;
  border-spacing: 0;
  overflow-x: auto;
  overflow-y: visible;

  tr:first-child > * {
    border-top-width: 2px;
  }

  table > tr:last-child > *,
  tbody > tr:last-child > * {
    border-bottom-width: 2px;
  }

  tr > :first-child {
    border-left-width: 2px;
  }

  tr > :last-child {
    border-right-width: 2px;
  }
}

.table_sticky > thead > tr:only-child > th:first-child,
.table_sticky > tbody > tr > th:first-child,
.sticky {
  position: sticky;
  left: 0;
  z-index: 2;
}


.dialogue {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  > * {
    flex: 1 0;
    min-width: 30ch;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  li::before {
    content: '– ';
  }
}

kbd {
  background-color: var(--ifm-background-surface-color);
  border-color: var(--ifm-table-border-color);
  box-shadow: inset 0 -2px 0 var(--ifm-table-border-color);
  color: var(--ifm-color-primary);
}

.text-3-col {
  column-count: 3;
  column-gap: 2rem;
}

.hash-link {
  transition: opacity .1s;
  position: absolute;

  &::before {
    background: var(--ifm-anchor-icon) 50%;
    content: '' !important;
    display: inline-block;
    height: 28px;
    min-width: 28px;
  }

  .anchor > & {
    margin-right: 15px;
    opacity: 0;
    text-decoration: none;
  }

  .anchor:hover > & {
    opacity: 0.20;

    &:hover {
      opacity: 0.5;
    }
  }
}

/*#region TOC */

.table-of-contents {
  padding-top: 0;
  padding-bottom: 0;
}

/*#endregion */

/*#region Footer */
.footer {
  &--dark {
    --ifm-footer-background-color: #213340;
  }

  &__title {
    font-weight: var(--ifm-font-weight-semibold);
    text-transform: uppercase;
  }
}
/*#endregion */

.pagination-nav {
  &__link {
    position: relative;
    border: 2px solid var(--ifm-toc-border-color);
    border-radius: 0;
  }

  &__label {
    word-break: normal;
  }

  &__label::before,
  &__label::after {
    display: none;
  }

  &__arrow {
    display: none;
  }

  &__link {
    padding: 12px 24px;

    @media screen and (max-width: 480px) {
      padding: 12px 12px;
    }
  }

  &__sublabel {
    text-transform: uppercase;
    font-weight: 600;
  }

}

#disqus_thread {
  margin-top: 2rem;
}
